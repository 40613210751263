@use "../../styles/constants.scss";

.createOrder {
    overflow: hidden;

    &__content {
        display: flex;
        flex-direction: column;
        gap: 30px;

        @media(max-width: 700px) {
            gap: 20px;
        }
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: constants.$white;
        padding: 15px;
        border-radius: 15px;
        box-shadow: 0px 0px 33px -11px rgba(0, 0, 0, 0.75);

        .header__info {
            display: flex;
            align-items: center;
            font-size: 21px;

            .title {
                font-weight: 600;
                font-size: 28px;

                @media(max-width: 700px) {
                    font-size: 21px;
                }
            }
        }

        .header__actions {
            .actions__close {
                width: 31px;
                height: 31px;
                position: relative;
                cursor: pointer;

                &:hover {

                    &::before,
                    &::after {
                        background-color: constants.$warning;
                    }
                }

                &::before {
                    content: "";
                    position: absolute;
                    top: 14.5px;
                    left: 0px;
                    width: 100%;
                    height: 2px;
                    background-color: constants.$grey;
                    transform: rotate(-45deg);
                    transition: ease .25s;
                }

                &::after {
                    content: "";
                    position: absolute;
                    bottom: 14px;
                    left: 0px;
                    width: 100%;
                    height: 2px;
                    background-color: constants.$grey;
                    transform: rotate(45deg);
                    transition: ease .25s;
                }
            }
        }
    }

    &__body {
        .form {
            display: flex;
            flex-direction: column;
            gap: 50px;

            @media(max-width: 700px) {
                gap: 20px;
            }

            .row {
                display: flex;
                gap: 40px;

                @media(max-width: 700px) {
                    flex-direction: column;
                    gap: 20px;
                }
            }

            &__content {
                flex: 1 1 50%;
                display: flex;
                flex-direction: column;
                gap: 15px;
                background-color: constants.$white;
                padding: 20px;
                border-radius: 15px;
                box-shadow: 0px 0px 33px -11px rgba(0, 0, 0, 0.75);

                .content__title {
                    font-size: 21px;
                    font-weight: 600;
                }

                .clientInfo {
                    flex: 1 1 50%;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                }

                .additionalInfo {
                    flex: 1 1 50%;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                }

                .techInfo {
                    flex: 1 1 50%;
                    display: flex;
                    gap: 75px;

                    @media(max-width: 700px) {
                        flex-direction: column;
                        gap: 20px;
                    }

                    &__column {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                    }
                }

                .input__section {
                    display: flex;
                    gap: 8px;
                    flex-direction: column;
                    position: relative;

                    .title {
                        font-size: 17px;
                        font-weight: 600;

                        span {
                            color: constants.$warning;
                        }
                    }

                    .input {
                        height: 45px;
                        width: 100%;
                    }

                    .search {
                        position: absolute;
                        top: 85px;
                        left: 0px;
                        z-index: 100;
                        width: 100%;
                        max-height: 250px;
                        overflow: auto;
                        display: flex;
                        flex-direction: column;
                        background-color: constants.$white;
                        padding: 15px;
                        border-radius: 15px;
                        box-shadow: 0px 0px 33px -11px rgba(0, 0, 0, 0.75);

                        &__item {
                            padding: 0px 5px;
                            border-bottom: 1px solid constants.$light-grey;
                            line-height: 2.2;
                            cursor: pointer;

                            &:hover {
                                color: constants.$blue ;
                                background-color: constants.$light-grey;
                            }
                        }

                        &_above {
                            position: absolute;
                            bottom: 55px;
                            left: 0px;
                            z-index: 100;
                            width: 100%;
                            max-height: 250px;
                            overflow: auto;
                            display: flex;
                            flex-direction: column-reverse;
                            background-color: constants.$white;
                            padding: 15px;
                            border-radius: 15px;
                            box-shadow: 0px 0px 33px -11px rgba(0, 0, 0, 0.75);

                            @media(max-width:700px) {
                                bottom: -220px
                            }
                        }
                    }

                    .error {
                        font-size: 14px;
                        color: constants.$warning;
                    }
                }
            }

            .button {
                width: 100%;
                height: 35px;
            }
        }
    }
}