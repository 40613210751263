@use "../../styles/constants.scss";

.order {
    &__body {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: constants.$white;
        padding: 15px;
        border-radius: 15px;
        box-shadow: 0px 0px 33px -11px rgba(0, 0, 0, 0.75);
        gap: 15px;

        @media(max-width:480px) {
            padding: 10px;
        }

        .header__info {
            display: flex;
            gap: 30px;
            font-size: 21px;

            @media(max-width:800px) {
                flex-direction: column;
                gap: 10px;
                font-size: 18px;
            }

            .header__row {
                display: flex;
                gap: 10px;

                .header__column {
                    white-space: nowrap;

                    .orderId {
                        font-weight: 700;

                        @media(max-width:380px) {
                            font-size: 16px;
                        }
                    }

                    .orderDate {
                        font-size: 18px;

                        @media(max-width:720px) {
                            font-size: 16px;
                        }

                        @media(max-width:380px) {
                            font-size: 14px;
                        }

                    }
                }

                .orderPrice {
                    color: constants.$grey;
                    font-weight: 600;
                    white-space: nowrap;

                    @media(max-width:380px) {
                        font-size: 14px;
                    }
                }
            }

            .statusDropDown {
                white-space: nowrap;

                @media(max-width: 920px) {
                    white-space: normal;
                }

                @media(max-width: 480px) {
                    white-space: nowrap;
                }
            }
        }

        .header__actions {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 15px;
            flex: 1 1 100%;

            @media(max-width:540px) {
                gap: 10px;
                flex-direction: column-reverse;

                align-items: flex-end;
            }

            .actions__copy {
                width: 30px;
                height: 30px;
                fill: constants.$grey;
                cursor: pointer;
                transition: ease .25s;

                @media(max-width:700px) {
                    width: 25px;
                    height: 25px;
                }

                &:hover {
                    fill: constants.$black2;
                }
            }

            .actions__pdf {
                width: 30px;
                height: 30px;
                fill: constants.$grey;
                cursor: pointer;
                transition: ease .25s;

                @media(max-width:700px) {
                    width: 25px;
                    height: 25px;
                }

                &:hover {
                    fill: constants.$black2;
                }
            }

            .actions__payment {
                width: 30px;
                height: 30px;
                fill: constants.$grey;
                cursor: pointer;
                transition: ease .25s;

                @media(max-width:700px) {
                    width: 25px;
                    height: 25px;
                }

                &:hover {
                    fill: green;
                }
            }

            .actions__edit {
                width: 25px;
                height: 25px;
                fill: constants.$grey;
                cursor: pointer;
                transition: ease .25s;

                @media(max-width:700px) {
                    width: 20px;
                    height: 20px;
                }

                &:hover {
                    fill: constants.$black2;
                }
            }

            .actions__close {
                width: 31px;
                height: 31px;
                position: relative;
                cursor: pointer;

                @media(max-width:700px) {
                    width: 25px;
                    height: 25px;
                }

                &:hover {

                    &::before,
                    &::after {
                        background-color: constants.$warning;
                    }
                }

                &::before {
                    content: "";
                    position: absolute;
                    top: 14.5px;
                    left: 0px;
                    width: 100%;
                    height: 2px;
                    background-color: constants.$grey;
                    transform: rotate(-45deg);
                    transition: ease .25s;

                    @media(max-width:700px) {
                        top: 12px;
                    }
                }

                &::after {
                    content: "";
                    position: absolute;
                    bottom: 14px;
                    left: 0px;
                    width: 100%;
                    height: 2px;
                    background-color: constants.$grey;
                    transform: rotate(45deg);
                    transition: ease .25s;

                    @media(max-width:700px) {
                        top: 12px;
                    }
                }
            }
        }
    }

    &__payment {
        display: flex;
        flex-direction: column;
        gap: 10px;
        background-color: constants.$white;
        padding: 15px;
        border-radius: 15px;
        box-shadow: 0px 0px 33px -11px rgba(0, 0, 0, 0.75);

        .payment__row {
            display: flex;
            align-items: flex-end;
            gap: 20px;

            .title {
                font-size: 21px;

                @media(max-width: 700px) {
                    font-size: 18px;
                }
            }

            .paymentType {
                font-size: 18px;

                @media(max-width: 700px) {
                    font-size: 16px;
                }
            }
        }

        .table {
            border-collapse: collapse;
            width: 100%;

            &__thead {}

            &__tbody {
                .table__row:hover {
                    background-color: constants.$light-grey;
                }
            }

            &__row {
                .paymentName {}

                .paymentAccepted {
                    @media(max-width: 840px) {
                        display: none;
                    }

                }

                .paymentExecutor {
                    @media(max-width: 840px) {
                        display: none;
                    }
                }

                .paymentCost {
                    @media(max-width: 450px) {
                        display: none;
                    }
                }

                .paymentPrice {}

                .paymentGuarantee {}

                .paymentDate {
                    @media(max-width: 550px) {
                        display: none;
                    }
                }
            }

            &__title {}

            &__item {
                text-align: left;
                line-height: 1.25;
                padding: 10px;

                @media(max-width: 700px) {
                    font-size: 14px;
                }

                span {
                    font-weight: 600;
                }
            }
        }
    }

    &__content {
        flex: 1 1 auto;
        display: flex;
        gap: 20px;

        @media(max-width: 950px) {
            flex-direction: column-reverse;
        }
    }

    &__history {
        max-height: calc(100vh - 100px);
        display: flex;
        flex-direction: column;
        gap: 10px;
        flex: 0 0 30%;
        background-color: constants.$white;
        padding: 15px;
        border-radius: 15px;
        box-shadow: 0px 0px 33px -11px rgba(0, 0, 0, 0.75);

        @media(max-width: 1150px) {
            flex: 0 0 25%;
        }

        .history__title {
            @media(max-width: 420px) {
                font-size: 18px;
            }
        }

        .history__input {
            display: flex;
            gap: 10px;

            .history__submitComment {
                flex: 0 0 80px;
            }
        }

        .history__comments {
            padding-right: 10px;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            gap: 10px;
            flex: 0 1 auto;

            .comment {
                padding: 5px;
                background-color: constants.$light-grey;
                border-radius: 10px;
                font-size: 14px;

                .message {}

                .submessage {
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    .date {
                        @media(max-width: 420px) {
                            font-size: 12px;
                        }
                    }

                    .author {
                        @media(max-width: 420px) {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }

    &__column {
        display: flex;
        flex-direction: column;
        gap: 20px;
        flex: 1 1 auto;
    }

    &__editMode {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: constants.$white;
        padding: 15px;
        border-radius: 15px;
        box-shadow: 0px 0px 33px -11px rgba(0, 0, 0, 0.75);

        @media(max-width: 610px) {
            flex-direction: column;
            gap: 10px;
        }

        .title {
            font-size: 28px;
            flex: 1 1 100%;

            @media(max-width: 950px) {
                font-size: 21px;
            }
        }

        .buttons {
            flex: 0 1 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 50px;

            @media(max-width: 610px) {
                justify-content: center;
                flex: 1 1 100%;
            }
        }

        .button {
            flex: 0 0 120px;
            height: 35px;
        }
    }

    &__info {
        display: flex;
        justify-content: space-between;
        gap: 30px;
        background-color: constants.$white;
        padding: 15px;
        border-radius: 15px;
        box-shadow: 0px 0px 33px -11px rgba(0, 0, 0, 0.75);
        height: 100%;

        @media(max-width: 700px) {
            flex-direction: column;
        }

        .order__column {
            display: flex;
            flex-direction: column;
            gap: 40px;


            .orderColumn__block {
                display: flex;
                flex-direction: column;
                gap: 20px;

                @media(max-width: 950px) {
                    align-items: center;
                }

                @media(max-width: 420px) {
                    gap: 10px;
                }
            }

            .order__title {
                @media(max-width: 420px) {
                    font-size: 18px;
                }
            }

            .orderInfo__content {
                .order__row {
                    padding: 16px;
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid constants.$grey;
                    width: 350px;

                    @media(max-width: 1150px) {
                        padding: 16px 10px;
                        width: 300px;
                    }

                    @media(max-width: 420px) {
                        padding: 10px;
                        width: 280px;
                    }

                    .orderInfo__title {
                        color: constants.$grey;
                        flex: 0 0 50%;
                        font-size: 16px;

                        @media(max-width: 950px) {
                            flex: 0 0 50%;
                        }

                        @media(max-width: 420px) {
                            font-size: 14px;
                        }
                    }

                    .input__section {
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        width: 100%;

                        .input {
                            & * {
                                @media(max-width: 420px) {
                                    font-size: 14px;
                                }
                            }
                        }

                        .dropdown {
                            position: absolute;
                            top: 60px;
                            left: 0px;
                            z-index: 100;
                            width: auto;
                            max-height: 250px;
                            overflow: auto;
                            display: flex;
                            flex-direction: column;
                            background-color: constants.$white;
                            padding: 15px;
                            border-radius: 15px;
                            box-shadow: 0px 0px 33px -11px rgba(0, 0, 0, 0.75);

                            &__item {
                                padding: 0px 5px;
                                border-bottom: 1px solid constants.$light-grey;
                                line-height: 2.2;
                                white-space: nowrap;
                                cursor: pointer;


                                &:hover {
                                    color: constants.$blue ;
                                    background-color: constants.$light-grey;
                                }
                            }
                        }

                        .error {
                            font-size: 14px;
                            color: constants.$warning;
                        }
                    }

                    .orderInfo__text {
                        flex: 0 0 50%;
                        display: flex;
                        justify-content: center;
                        text-align: center;

                        @media(max-width: 420px) {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}