@use "../../../styles/constants.scss";

.modal {}

.form {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.title {
    font-size: 24px;
    text-align: center;
    white-space: nowrap;
}

.buttons {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .button {
        height: 35px;
        width: 100%;
    }
}