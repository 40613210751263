@use "../../styles/constants.scss";

.loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
}

.spinner {
    border: 16px solid #f3f3f3;
    border-top: 16px solid constants.$blue;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.text {
    font-size: 24px;
    text-align: center;
    color: constants.$blue;
}