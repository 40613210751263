@use "../../styles/constants.scss";

@font-face {
    font-family: "DejaVu Sans";
    src: url("https://kendo.cdn.telerik.com/2020.1.114/styles/fonts/DejaVu/DejaVuSans.ttf") format("truetype");
}

@font-face {
    font-family: "DejaVu Sans";
    font-weight: bold;
    src: url("https://kendo.cdn.telerik.com/2020.1.114/styles/fonts/DejaVu/DejaVuSans-Bold.ttf") format("truetype");
}

@page {
    size: auto;
    margin: 30px;
}

@media print {
    .pdf {
        padding: 0px;
        border-radius: none;
        box-shadow: none;
    }

    header {
        display: none;
    }

    .button {
        display: none;
    }

    .content_first,
    .content_second {
        background-color: white;
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        margin: 0;
    }
}

.body {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 15px;

    .button {
        height: 35px;
    }
}

.pdf {
    font-family: sans-serif;
    letter-spacing: .002em;
    background-color: constants.$white;
    padding: 15px;
    border-radius: 15px;
    box-shadow: 0px 0px 33px -11px rgba(0, 0, 0, 0.75);

    .container {
        padding: 15px;
        margin: 0 auto;
    }

    // First PDF

    .content_first {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .header {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            gap: 10px;

            .title {
                flex: 1 1 100%;

                h1 {
                    font-size: 27px;
                    font-weight: 700;

                }

                p {
                    font-size: 21px;

                    span {
                        font-weight: 700;
                    }
                }
            }

            .serviceInfo {
                text-align: center;
                font-size: 14px;

                p {
                    span {
                        font-weight: 700;
                    }
                }
            }

            .logo {
                flex: 0 0 160px;
                height: 75px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .table {
            border: 1px solid black;
            text-align: left;
            border-collapse: collapse;
            width: 100%;

            &__row {
                border: 1px solid black;
            }

            &__title {
                border: 1px solid black;
                padding: 5px;
                font-weight: 700;
                font-size: 14px;
                width: 120px;
            }

            &__text {
                padding: 7px;
                font-size: 13px;
            }
        }

        .serviceRules {
            display: flex;
            flex-direction: column;
            gap: 4px;
            margin-bottom: 40px;
            padding: 0px 10px;

            &__title {
                font-size: 14px;
                font-weight: 700;
            }

            &__list {
                display: flex;
                flex-direction: column;
                gap: 3px;
                line-height: 1.8;
                list-style-type: decimal;
                padding: 0px 10px;
            }

            &__text {
                font-size: 9px;
            }
        }

        .signature {
            display: flex;
            justify-content: space-between;
            position: relative;

            &__column {
                display: flex;
                flex-direction: column;
                gap: 10px;
                position: relative;
                padding-top: 10px;
                border-top: 1px solid black;
            }

            &__text {
                font-size: 14px;

                span {
                    font-weight: 700;
                }
            }
        }
    }

    // second PDF
    .content_second {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .header {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            gap: 10px;

            .title {
                flex: 1 1 100%;

                &_second {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                h1 {
                    font-size: 32px;
                    font-weight: 700;

                }

                p {
                    font-size: 18px;

                    span {
                        font-weight: 700;
                    }
                }
            }

            .serviceInfo {
                text-align: center;
                font-size: 14px;

                p {
                    span {
                        font-weight: 700;
                    }
                }
            }

            .logo {
                flex: 0 0 160px;
                height: 75px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .table {
            border: 1px solid black;
            text-align: left;
            border-collapse: collapse;
            width: 100%;

            &__row {
                display: flex;

                &:not(:last-child) {
                    border-bottom: 1px solid black;
                }
            }

            &__title {
                border-right: 1px solid black;
                padding: 5px;
                font-weight: 700;
                font-size: 14px;
                flex: 0 0 120px;
            }

            &__text {
                padding: 7px;
                font-size: 13px;
            }

            &__row_additional {
                display: flex;

                &:not(:last-child) {
                    border-bottom: 1px solid black;
                }
            }

            &__title_additional {
                padding: 5px;
                font-weight: 700;
                font-size: 14px;
                flex: 1 1 auto;
                border-right: 1px solid black;

                span {
                    font-weight: 400;
                }
            }

            &__text_additional {
                padding: 7px;
                font-size: 13px;
                flex: 0 0 135px;
                text-align: right;

                &:not(:last-child) {
                    border-right: 1px solid black;
                }

                white-space: nowrap;
            }
        }

        .terms {
            margin-top: -12px;

            &__title {
                font-size: 14px;
            }

            &__text {
                font-size: 12px;
            }
        }

        .signature {
            margin: 40px 0px 20px 0px;
            display: flex;
            justify-content: space-between;
            position: relative;

            &__date {
                position: absolute;
                top: -10px;
                left: 50%;
                transform: translateX(-50%);
                font-size: 14px;
                font-weight: 700;
            }

            &__stroke {
                position: absolute;
                bottom: -30px;
                left: 50%;
                transform: translateX(-50%);
                white-space: nowrap;
            }

            &__column {
                display: flex;
                flex-direction: column;
                gap: 10px;
                padding-top: 10px;
                border-top: 1px solid black;
            }

            &__text {
                font-size: 12px;

                span {
                    font-weight: 700;
                }
            }
        }
    }
}