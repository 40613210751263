@use "../../styles/constants.scss";

.dropdown {
    line-height: 2;
    position: relative;
    color: white;

    .header {
        display: flex;
        align-items: center;
        gap: 5px;

        p {
            cursor: pointer;

        }
    }
}

.body {
    z-index: 1;
    position: absolute;
    top: 100%;
    left: -25px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-width: 100%;
    height: auto;
    background-color: white;
    padding: 25px;
    border-radius: 16px;
    transition: all .5s ease;
    box-shadow: 0px 20px 48px rgba(177, 177, 177, 0.4);
}

.item {
    border-radius: 15px;
    padding: 5px 5px 5px 15px;
    font-size: 16px;
    line-height: 150%;
    font-weight: 500;
    padding: 3px 10px;

    @media(max-width: 700px) {
        font-size: 14px;
    }

    &:hover {
        cursor: pointer;
    }
}

.blue {
    background-color: constants.$blue;
}

.yellow {
    background-color: rgb(255, 255, 30);
    color: black;
}

.cyan {
    background-color: rgb(52, 187, 187);
}

.red {
    background-color: constants.$warning;
}

.blackGreen {
    background-color: rgb(3, 88, 3);
}

.orange {
    background-color: rgb(255, 125, 18);
}

.green {
    background-color: rgb(16, 173, 16);
}

.violet {
    background-color: rgb(179, 2, 179);
}

.black {
    background-color: black;
}

.grey {
    background-color: rgb(143, 46, 46);
}