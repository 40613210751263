@use "../../styles/constants.scss";

.login {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: constants.$light-grey;
}

.form {
    background-color: white;
    padding: 30px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 350px;
}

.input {
    height: 50px;
    width: 100%;
}

.button {
    height: 40px;
    width: 100%;
}

.error {
    color: constants.$warning;
}