.modal {
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow: auto;

    &__title {
        font-size: 26px;
    }

    &__form {
        display: flex;
        flex-direction: column;
        gap: 25px;

        .input__section {
            display: flex;
            flex-direction: column;
            gap: 8px;

            .input__title {
                font-size: 21px;
                font-weight: 600;
            }

            .input {
                padding: 0px 1px;
                height: 40px;
            }
        }

        .button {
            width: 100%;
            height: 35px;
        }
    }
}