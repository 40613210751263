// @use "../../styles/constants.scss";

.modal__background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: rgba(23, 73, 179, 0.1);
    backdrop-filter: blur(7.5px);
    overflow: hidden;
}

.modal__content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 40px;
    box-shadow: 0px 4px 24px rgba(213, 216, 221, 0.2);
    border-radius: 32px;
    z-index: 200;
    overflow: hidden;

    @media(max-width:700px) {
        padding: 20px;
    }
}