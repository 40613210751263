@use "../../styles/constants.scss";

.header {
    position: fixed;
    top: 0px;
    width: 100%;
    background-color: constants.$background;
    z-index: 10;
    box-shadow: 0px 0px 20px -11px rgba(0, 0, 0, 0.75);

    &__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 50px;
        padding: 10px 0px;

        @media(max-width: 450px) {
            gap: 30px;
        }
    }

    .navigate {
        display: flex;
        gap: 25px;

        @media(max-width: 450px) {
            gap: 20px;
        }

        li {
            display: flex;
            align-items: center;
            justify-content: center;

        }

        &__logo {
            background-color: #cacaca;
            padding: 8px;
            border-radius: 12px;

            @media(max-width: 450px) {
                padding: 6px;
            }

            p {
                font-size: 24px;
                font-weight: 700;
                letter-spacing: .0005rem;

                @media(max-width: 450px) {
                    font-size: 21px;
                }

                span {
                    color: constants.$warning;
                }
            }
        }

        &__link {
            color: inherit;
            text-decoration: none;
            padding: 8px;
            border-radius: 12px;
            transition: all ease .25s;

            @media(max-width: 450px) {
                padding: 6px;
            }

            &_active {
                color: inherit;
                text-decoration: none;
                padding: 8px;
                border-radius: 12px;
                transition: all ease .25s;
                background-color: #cacaca;

                @media(max-width: 450px) {
                    padding: 6px;
                }
            }

            &:hover {
                background-color: #cacaca;
            }
        }

        &__icon {
            width: 25px;
            height: 25px;

            @media(max-width: 450px) {
                width: 20px;
                height: 20px;
            }

            svg {
                width: 100%;
                height: 100%;
            }
        }
    }


    &__actions {
        display: flex;
        align-items: center;

        .header__account {
            display: flex;
            align-items: center;
            gap: 8px;
            background-color: constants.$light-grey;
            padding: 5px 10px;
            border-radius: 15px;
            cursor: pointer;

            &:hover {
                svg {
                    fill: constants.$blue;
                }
            }

            @media(max-width: 450px) {
                font-size: 14px;
            }

            svg {
                transition: ease .25s;
                fill: constants.$black;
                width: 30px;
                height: 30px;
                object-fit: cover;
                mix-blend-mode: multiply;

                @media(max-width: 450px) {
                    width: 25px;
                    height: 25px;
                }
            }

            p {}
        }
    }
}