@use "./constants.scss";

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    text-decoration: none;
}

html,
body,
#root {
    height: 100%;
    width: 100%;
    font-size: 16px;
    font-family: constants.$Manrope;
    font-weight: 400;
    letter-spacing: .1em;
    color: constants.$black2;
    background-color: constants.$background;
}

body {
    overflow-x: hidden;
}

.wrapper {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.header {}

.main {
    flex: 1 1 auto;
    margin-top: 84px;
    padding-bottom: 20px;
}

.footer {}

.footer {
    z-index: 100;
    background-color: white;
}

.container {
    max-width: 1270px;
    margin: 0 auto;
    padding: 0px 15px;
}

ul,
ol {
    list-style-type: none;
}

a {
    color: inherit;
    fill: inherit;
}