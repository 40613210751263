@use "../../../styles/constants.scss";

.label {
    position: relative;

    &:hover {
        .icon {
            fill: constants.$blue;
        }
    }

    &:active {
        .icon {
            fill: constants.$light-blue;
        }
    }

    .icon {
        position: absolute;
        width: 20px;
        height: 20px;
        top: 0;
        left: 10px;
        fill: constants.$grey;
    }

    .input {
        border: none;
        outline: 1px solid #F1F4F8;
        background: #F1F4F8;
        border-radius: 8px;
        padding: 16px 24px 16px 40px;
        font-family: 'Gotham Pro';
        font-size: 17px;
        line-height: 130%;
        font-weight: 500;
        color: black;
        width: 100%;
        height: 100%;
        box-shadow: inset 0px 0px 12px -11px rgba(0, 0, 0, 0.75);

        @media(max-width: 700px) {
            font-size: 15px;
            padding: 12px 24px 12px 40px;
        }

        &::placeholder {
            color: #9C99AD;
            font-weight: 400;
        }

        &:hover {
            outline: 1px solid #838099;
        }

        &:focus {
            outline: 1px solid #CECBDF;
        }
    }
}