.select {
    border: none;
    outline: 1px solid #F1F4F8;
    background: #F1F4F8;
    border-radius: 8px;
    padding: 8px 16px;
    font-family: 'Gotham Pro';
    font-size: 17px;
    line-height: 130%;
    font-weight: 500;
    color: black;
    width: 100%;
    height: 100%;
    box-shadow: inset 0px 0px 12px -11px rgba(0, 0, 0, 0.75);
    appearance: none;
    background-image: url("../../../assets/arrow-down.png");
    background-repeat: no-repeat;
    background-position: right 1em top 50%, 0 0;
    background-size: .65em auto, 100%;

    &:focus {
        background-image: url("../../../assets/arrow-up.png");
    }
}

.option {}