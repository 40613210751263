// @use "../../../styles/constants.scss";

.input {
    border: none;
    outline: 1px solid #F1F4F8;
    background: #F1F4F8;
    border-radius: 8px;
    padding: 16px;
    font-family: 'Gotham Pro';
    font-size: 17px;
    line-height: 130%;
    font-weight: 500;
    color: black;
    width: 100%;
    height: 100%;
    box-shadow: inset 0px 0px 12px -11px rgba(0, 0, 0, 0.75);

    &::placeholder {
        color: #9C99AD;
        font-weight: 400;
    }

    &:hover {
        outline: 1px solid #838099;
    }

    &:focus {
        outline: 1px solid #CECBDF;
    }
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}