@use "../../../styles/constants.scss";

.blackButton {
    background-color: constants.$black;
    border: 1px solid constants.$black;

    .buttonText {
        color: constants.$white;
    }

    &:hover {
        background-color: rgba(255, 255, 255, 0);
        border: 1px solid constants.$black;

        .buttonText {
            color: constants.$black2;
        }
    }

    &:active {
        border: 1px solid constants.$grey;
        background: constants.$grey;

        .buttonText {
            color: constants.$white;
        }
    }
}

.whiteButton {
    background-color: constants.$white;
    border: 1px solid constants.$white;

    .buttonText {
        color: constants.$black2;
    }

    &:hover {
        background-color: rgba(255, 255, 255, 0);
        border: 1px solid constants.$white;

        .buttonText {
            color: constants.$white;
        }
    }

    &:active {
        border: 1px solid constants.$light-blue;
        background: rgba(255, 255, 255, 0.8);

        .buttonText {
            color: constants.$black2;
        }
    }
}

.blueButton {
    background-color: constants.$blue;
    border: 1px solid constants.$blue;

    .buttonText {
        color: constants.$white;
    }

    &:hover {
        border: 1px solid constants.$blue;
        background-color: rgba(255, 255, 255, 0);

        .buttonText {
            color: constants.$black2;
        }
    }

    &:active {
        border: 1px solid constants.$light-blue;
        background: constants.$light-blue;

        .buttonText {
            color: constants.$white;
        }
    }
}

.button {
    padding: 5px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border: none;
    cursor: pointer;
    transition: all .25s ease;
}

.buttonText {

    font-weight: 700;
    font-size: 17px;
    line-height: 130%;
    transition: all .25s ease;

    @media(max-width: 700px) {

        font-size: 14px;
    }
}

.active {
    background-color: constants.$grey;
}

.disabled {
    background-color: constants.$grey;
    cursor: auto;

    &:hover {
        border: 1px solid constants.$grey;
        background-color: constants.$grey;

        .buttonText {
            color: constants.$white;
        }
    }

    &:active {
        border: 1px solid constants.$grey;
        background-color: constants.$grey;

        .buttonText {
            color: constants.$white;
        }
    }
}