@use "../../../styles/constants.scss";

.modal {
    width: 60vw;

    .payment {
        display: flex;
        flex-direction: column;
        gap: 15px;

        .modal__header {
            display: flex;
            align-items: center;

            @media(max-width:480px) {
                flex-direction: column;
            }

            .title {
                white-space: nowrap;

                @media(max-width:700px) {
                    font-size: 21px;
                }
            }

            .date {
                font-size: 18px;
                margin-left: 15px;

                @media(max-width:700px) {
                    font-size: 16px;
                }
            }
        }

        .form {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .inputSection {
                display: flex;
                flex-direction: column;
                gap: 5px;
                flex: 1 1 50%;

                h3 {
                    @media(max-width:700px) {
                        font-size: 16px;
                    }

                    span {
                        color: constants.$warning;
                    }
                }

                .input {
                    height: 45px;

                    & * {
                        @media(max-width:700px) {
                            font-size: 14px;
                        }
                    }
                }

                label {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                }

                .text {
                    line-height: 3;
                    font-size: 18px;
                    font-weight: 600;

                    @media(max-width:700px) {
                        font-size: 14px;
                    }
                }

                .error {
                    font-size: 14px;
                    color: constants.$warning;
                }
            }

            .row {
                display: flex;
                gap: 50px;

                @media(max-width:830px) {
                    flex-direction: column;
                }
            }

            .button {
                margin-top: 15px;
                width: 100%;
                height: 35px;
            }
        }
    }
}