@use "../../../styles/constants.scss";

.modal {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media(max-width: 700px) {
        gap: 10px;
    }

    &__title {
        font-size: 28px;
        text-align: center;

        @media(max-width: 700px) {
            font-size: 21px;
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        gap: 20px;
        font-size: 18px;
    }

    &__row {
        display: flex;
        gap: 25px;

        p {
            @media(max-width: 700px) {
                font-size: 16px;
            }
        }
    }

    &__inputSection {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 8px;
    }

    &__inputTitle {
        @media(max-width: 700px) {
            font-size: 18px;
        }
    }

    &__label_radio {
        display: flex;
        gap: 5px;

        @media(max-width: 700px) {
            font-size: 16px;
        }
    }

    &__radio {}

    &__submit {
        margin-top: 20px;
        height: 30px;

        @media(max-width: 700px) {
            margin: 0px;
        }
    }
}