.notFound {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;

    .content {
        display: flex;
        flex-direction: column;
        gap: 25px;

        .title {
            font-size: 48px;
        }

        .button {
            height: 40px;
        }
    }
}