@use "../../styles/constants.scss";

.order {
    padding: 5px 15px 0px 15px;

    &__content {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    &__actions {
        margin: 0px -15px;

        .orderActions {
            display: flex;
            align-items: center;
            gap: 15px;

            @media(max-width: 1024px) {
                flex-direction: column-reverse;
            }

            @media(max-width: 600px) {}

            .actions__search {
                flex: 1 1 100%;
                height: 45px;

                @media(max-width: 1024px) {
                    width: 100%;
                }

                @media(max-width: 700px) {
                    flex: 0 0 25px;
                }

                @media(max-width: 600px) {}
            }

            .actions__buttons {
                flex: 1 1 auto;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                gap: 15px;

                @media(max-width: 1024px) {
                    width: 100%;

                }

                @media(max-width: 700px) {
                    flex-direction: column;
                }

                .createButtons {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    gap: 15px;

                    @media(max-width: 1024px) {
                        flex: 1 1 100%;
                        width: 100%;
                    }

                    @media(max-width: 700px) {
                        justify-content: flex-start;
                    }
                }

                .button {
                    width: 120px;
                    height: 45px;

                    @media(max-width: 700px) {
                        flex: 1 1 auto;
                        height: 35px;
                    }
                }
            }
        }
    }

    &__table {
        // overflow-x: scroll;

        .table {
            border-collapse: collapse;
            width: 100%;

            &__header {}

            &__body {
                .table__row:hover {
                    background-color: #c0c0c0;
                }
            }

            &__row {
                border-bottom: 1px solid constants.$grey;
            }

            .even__row {
                background-color: constants.$light-grey;
            }

            &__item {
                text-align: left;
                line-height: 1.25;
                padding: 10px;

                @media(max-width: 700px) {
                    font-size: 14px;
                    padding: 8px;
                }

                span {
                    font-weight: 600;
                }
            }

            .nowrap {
                white-space: nowrap;

                @media(max-width: 450px) {
                    white-space: normal;
                }
            }
        }
    }
}